<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <message-left-panel></message-left-panel>

            </div>


        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <div class='grid p-0'>
                    <div class='col-10'>
                        <div class='card-header widget-chat font-bold text-2xl mb-1'>{{ dataItem.subject }}</div>
                        <Tag v-if='dataItem.type == "Acil"' icon='pi pi-bell' severity='danger' value='Acil'
                             class='p-1 font-bold text-sm'></Tag>
                        <Tag v-if='dataItem.type == "Normal"' icon='pi pi-check' severity='success' value='Normal'
                             class='p-1 font-bold text-sm'></Tag>
                    </div>
                    <div class='col-2'>
                        <Button v-if='showCloseButton' label='Talebi Kapat' icon='pi pi-check'
                                iconPos='right' class='mr-2 mb-2' @click='closeRequest'></Button>

                        <Button v-if='showOpenButton' label='Talebi Tekrar Aç' icon='pi pi-check'
                                iconPos='right' class='mr-2 mb-2' @click='openRequest'></Button>
                    </div>
                </div>

                <hr>

                <div class='widget-chat'>
                    <ul id='chatcontainer' class='list-none p-1 px-3 mt-4 mb-6 overflow-y-auto'
                        v-if='dataItem.sender != null'>
                        <li :class="['flex align-items-start mb-3', {'justify-content-start': dataItem.nth, 'justify-content-end': !dataItem.nth}]">
                            <img v-if='dataItem.nth'
                                 :src='"http://image.a1catering.com.tr/" + dataItem.sender.profilePicture' width='48'
                                 height='48'
                                 class='border-circle'>
                            <div class='ml-2' v-if='dataItem.nth'>
                                <span class='font-bold mr-3'>{{ dataItem.sender.fullName }}</span><span class='subtext'>{{ formatDate(dataItem.insertDate)
                                }}</span>
                                <div class='message-nth px-5 py-3 mt-3'>{{ dataItem.text }}
                                </div>
                                <div class='flex align-items-center flex-column sm:flex-row mt-2'>
                                    <Chip v-for='file of dataItem.attachedFiles' :key='file.id' label='Dosya'
                                          icon='pi pi-file' class='mr-2 mb-2' @click='showFile(file)'></Chip>
                                </div>
                            </div>
                        </li>

                        <li v-for='chatMessage of dataItem.subMessages' :key='chatMessage.id'
                            :class="['flex align-items-start mb-3', {'justify-content-start': chatMessage.nth, 'justify-content-end': !chatMessage.nth}]">
                            <template v-if='chatMessage.nth != null'>
                                <img v-if='chatMessage.nth'
                                     :src='"http://image.a1catering.com.tr/" + chatMessage.sender.profilePicture'
                                     width='48' height='48'
                                     class='border-circle'>
                                <div class='ml-2' v-if='chatMessage.nth'>
                                    <span class='font-bold mr-3'>{{ chatMessage.sender.fullName }}</span><span
                                    class='subtext'>{{ formatDate(chatMessage.insertDate) }}</span>
                                    <div class='message-nth px-5 py-3 mt-3'>{{ chatMessage.text }}
                                    </div>
                                    <div class='flex align-items-center flex-column sm:flex-row mt-2'>
                                        <Chip v-for='file of chatMessage.attachedFiles' :key='file.id' label='Dosya'
                                              icon='pi pi-file' class='mr-2 mb-2' @click='showFile(file)'></Chip>
                                    </div>
                                </div>

                                <div class='mr-3' v-if='!chatMessage.nth'>
                                    <span class='subtext'>{{ formatDate(chatMessage.insertDate) }}</span><span
                                    class='font-bold ml-3'>{{ chatMessage.sender.fullName }}</span>
                                    <div class='message-2nth px-5 py-3 mt-3'>
                                        {{ chatMessage.text }}
                                    </div>
                                    <div class='flex align-items-center flex-column sm:flex-row mt-2'>
                                        <Chip v-for='file of chatMessage.attachedFiles' :key='file.id' label='Dosya'
                                              icon='pi pi-file' class='mr-2 mb-2' @click='showFile(file)'></Chip>
                                    </div>
                                </div>
                                <img v-if='!chatMessage.nth'
                                     :src='"http://image.a1catering.com.tr/" + chatMessage.sender.profilePicture'
                                     width='48' height='48'
                                     class='border-circle'>
                            </template>

                        </li>
                    </ul>

                    <div class='p-inputgroup write-message mt-3'>
                            <span class='p-inputgroup-addon'>
                                <Button type='button' icon='pi pi-send' class='p-button-text p-button-plain' @click='sendAnswer'></Button>
                            </span>
                        <InputText v-model='answerText' placeholder='Mesajınız...'
                                   v-on:blur='saveBeforeAddFile' />
                    </div>



                </div>

                <div class='grid p-0 mt-4'>


                            <div class='col-2'>
                            <FileUpload v-if='upload1Visible && showUploadButton' choose-label='Dosya Ekle 01'
                                        mode='basic'
                                        name='upload[]' :url='uploadUrlForPicture1' :auto='true'
                                        @select='photoUploadStarted("PictureSlot1")'
                                        @upload="photoUploadFinished('PictureSlot1')" />
                            <Button id='deleteFile1' label='Dosya 01 Sil' icon='pi pi-times'
                                    @click='deletePicture("PictureSlot1")'
                                v-if='!upload1Visible' class='p-button-danger w-full'></Button>
                            </div>

                            <div class='col-2'>
                            <FileUpload v-if='upload2Visible && showUploadButton' choose-label='Dosya Ekle 02'
                                        mode='basic'
                                        name='upload[]' :url='uploadUrlForPicture2' :auto='true'
                                        @before-send='photoUploadStarted("PictureSlot2")'
                                        @upload="photoUploadFinished('PictureSlot2')" />
                            <Button id='deleteFile2' label='Dosya 02 Sil' icon='pi pi-times'
                                    @click='deletePicture("PictureSlot2")'
                                    v-if='!upload2Visible' class='p-button-danger'></Button>
                            </div>

                            <div class='col-2'>
                            <FileUpload v-if='upload3Visible && showUploadButton' choose-label='Dosya Ekle 03'
                                        mode='basic'
                                        name='upload[]' :url='uploadUrlForPicture3' :auto='true'
                                        @before-send='photoUploadStarted("PictureSlot3")'
                                        @upload="photoUploadFinished('PictureSlot3')" />
                            <Button id='deleteFile3' label='Dosya 03 Sil' icon='pi pi-times'
                                    @click='deletePicture("PictureSlot3")'
                                    v-if='!upload3Visible' class='p-button-danger'></Button>
                            </div>

                        </div>

            </div>

        </div>


    </div>
</template>

<script>

import { showErrorMessage, showSuccessMessage, showValidationMessage } from '@/components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import { getUserId, normalizeDate } from '../common/commonConstantFunctions';
import MessageService from '@/services/messageService';
import moment from 'moment';
import data from 'bootstrap/js/src/dom/data';
import MessageLeftPanel from '@/pages/messages/messageLeftPanel.vue';


export default {
    computed: {
        data() {
            return data;
        },
        showUploadButton() {
            return this.answerText.length > 0;
        },
        showCloseButton() {
            return this.dataItem.status != "Kapalı" && this.dataItem.senderId == getUserId();
        },
        showOpenButton() {
            return this.dataItem.status != "Açık" && this.dataItem.senderId == getUserId();
        },
    },
    _messageService: null,
    components: { MessageLeftPanel, AdminToast },
    async created() {
        this._messageService = new MessageService();
        this.senderId = this.$route.params.senderId;
        this.receiverId = this.$route.params.receiverId;
        this.messageId = this.$route.params.messageId;

    },
    async mounted() {
        this.dataItem.managerUserId = getUserId();
        await this.getMessage();
        if(this.dataItem.receiverId != getUserId() && this.dataItem.senderId != getUserId())
        {
            this.$router.push({ name: 'access' });
            return;
        }
        if ((this.dataItem.isReceiverRead == null || this.dataItem.isReceiverRead == false) && this.dataItem.receiverId == getUserId()) {
            await this._messageService.updateMessageReceiverReadStatus(this.dataItem.id, true);
        }

        if ((this.dataItem.isSenderRead == null || this.dataItem.isSenderRead == false) && this.dataItem.senderId == getUserId()) {
            await this._messageService.updateMessageSenderReadStatus(this.dataItem.id, true);

        }

    },
    data() {
        return {
            isProcessing: false,
            display: false,
            visibleLeft: false,
            answerText: '',
            answerId: 0,
            uploadUrlForPicture1: '',
            uploadUrlForPicture2: '',
            uploadUrlForPicture3: '',
            upload1Visible: true,
            upload2Visible: true,
            upload3Visible: true,
            dataItem: {
                id: '',
                receiverId: '',
                senderId: '',
                parentMessageId: '',
                text: '',
                type: '',
                status: '',
                subject: '',
                insertDate: '',
                closeDate: '',
                isReceiverRead: false,
                isSenderRead: false,
                subMessages: [],
            },
            chatMessages: [
                {
                    nth: true,
                    from: 'Tarık Terzi',
                    url: 'http://image.a1catering.com.tr/ManagerUser/3/ProfilePhoto_9af618a2-4de0-476a-85bf-8a584b12c472.jpg',
                    messages: ['Doğan Arslan isimli personelin SGK giriş evrağı tarafıma ulaşmadı. Ulaştırılmasını rica ederim. '],
                },
                {
                    nth: false,
                    from: 'Caner Yeşil',
                    url: 'http://image.a1catering.com.tr/ManagerUser/16/ProfilePhoto_6a38216c-5bb4-460b-a745-38044ec27e94.jpg',
                    messages: ['Talebiniz alındı Tarık bey en kısa sürede dönüş yapılacaktır.'],
                },
                {
                    nth: false,
                    from: 'Caner Yeşil',
                    url: 'http://image.a1catering.com.tr/ManagerUser/16/ProfilePhoto_6a38216c-5bb4-460b-a745-38044ec27e94.jpg',
                    messages: ['İlgili personelin SGK giriş dosyası eklenmiştir. Talebiniz tamamlandı ise lütfen kapatınız.'],
                },
            ],

        };
    },
    methods: {

        showFile(file) {
            window.open('http://image.a1catering.com.tr' + file.filePath, '_blank');
        },


        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },
        async save() {
            this.isProcessing = true;
            if (await this.validateForm()) {

                this.dataItem.birthday = normalizeDate(this.dataItem.birthday);

                let insertResponse = await this._employeeService.insertEmployee(this.dataItem);
                if (insertResponse.isSuccess) {
                    await this.$router.push({ name: 'editEmployee', params: { employeeId: insertResponse.data } });
                } else {
                    showErrorMessage(this, 'Mesaj göndermede Sorun İle Karşılaşıldı');
                }
            }
            this.isProcessing = false;
        },
        async sendAnswer() {

            if(this.answerText.trim() == '' ||this.answerText == null)
            {
                showErrorMessage(this, "Boş mesaj gönderemezsiniz");
                return;
            }
            await this._messageService.updateMessageAnswer(this.answerId, this.answerText);
            if(this.dataItem.senderId == getUserId())
            {
                await this._messageService.updateMessageReceiverReadStatus(this.dataItem.id);
            }
            else
            {
                await this._messageService.updateMessageSenderReadStatus(this.dataItem.id);
            }
            showSuccessMessage(this, 'Cevabınız eklendi');
            await this.getMessage();
            this.uploadUrlForPicture1 = '';
            this.upload1Visible = true;

            this.uploadUrlForPicture2 = '';
            this.upload2Visible = true;

            this.uploadUrlForPicture3 = '';
            this.upload3Visible = true;

            this.answerId = 0;
            this.answerText = '';
        },
        async closeRequest() {
            await this._messageService.updateMessageStatus(this.dataItem.id, 'Kapalı');
            await this.getMessage();
        },

        async openRequest() {
            await this._messageService.updateMessageStatus(this.dataItem.id, 'Açık');
            await this.getMessage();
        },
        async getMessage() {
            let getResponse = await this._messageService.getMessageById(this.senderId, this.receiverId, this.messageId);
            if (getResponse.isSuccess) {

                this.dataItem.id = getResponse.data.data[0].id;
                this.dataItem.receiverId = getResponse.data.data[0].receiverId;
                this.dataItem.senderId = getResponse.data.data[0].senderId;
                this.dataItem.parentMessageId = getResponse.data.data[0].parentMessageId;
                this.dataItem.text = getResponse.data.data[0].text;
                this.dataItem.type = getResponse.data.data[0].type;
                this.dataItem.status = getResponse.data.data[0].status;
                this.dataItem.subject = getResponse.data.data[0].subject;
                this.dataItem.insertDate = getResponse.data.data[0].insertDate;
                this.dataItem.closeDate = getResponse.data.data[0].closeDate;
                this.dataItem.isReceiverRead = getResponse.data.data[0].isReceiverRead;
                this.dataItem.isSenderRead = getResponse.data.data[0].isSenderRead;
                this.dataItem.subMessages = getResponse.data.data[0].subMessages;
                this.dataItem.sender = getResponse.data.data[0].sender;
                this.dataItem.receiver = getResponse.data.data[0].receiver;
                this.dataItem.nth = getResponse.data.data[0].nth;
                this.dataItem.attachedFiles = getResponse.data.data[0].attachedFiles;
            }
        },
        async validateForm() {
            if (this.answerText.trim() == '') {
                showValidationMessage(this, 'Mesaj Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },

        async photoUploadFinished(fileType) {
            if (fileType == 'PictureSlot1') {
                this.upload1Visible = false;
            } else if (fileType == 'PictureSlot2') {
                this.upload2Visible = false;
            } else if (fileType == 'PictureSlot3') {
                this.upload3Visible = false;
            }
            this.loadingIndicator = false;
        },
        async photoUploadStarted() {
            if (await this.validateForm()) {

                this.loadingIndicator = true;
            } else {
                return;
            }

        },
        async deletePicture(fileType) {
            let response = await this._messageService.deleteMessagePicture(fileType, this.answerId, 'MessagePictures');
            if (response.isSuccess) {
                showSuccessMessage(this, 'Silme işlemi başarılı');
                if (fileType == 'PictureSlot1') {
                    this.upload1Visible = true;
                } else if (fileType == 'PictureSlot2') {
                    this.upload2Visible = true;
                } else if (fileType == 'PictureSlot3') {
                    this.upload3Visible = true;
                }
            }
        },
        async saveBeforeAddFile() {
            if(this.answerText.trim() =='' || this.answerText == null)
            {
                return;
            }
            if (await this.validateForm()) {
                let draftMessage = {
                    receiverId: getUserId() == this.dataItem.senderId ? this.dataItem.receiverId : this.dataItem.senderId,
                    senderId: getUserId() == this.dataItem.senderId ? this.dataItem.senderId : this.dataItem.receiverId,
                    parentMessageId: this.dataItem.id,
                    text: this.answerText,
                    subject: '',
                    status: 'Taslak',
                    type: 'Normal',
                    isActive: false,
                };

                let insertResponse = await this._messageService.insertMessageAsDraft(draftMessage);
                if (insertResponse.isSuccess) {
                    this.answerId = insertResponse.data;
                    this.uploadUrlForPicture1 = process.env.VUE_APP_MAIN_API + '/api/upload/file?fileType=PictureSlot1&resourceId=' + this.answerId + '&resourceType=MessagePictures&managerUserId=' + getUserId();
                    this.uploadUrlForPicture2 = process.env.VUE_APP_MAIN_API + '/api/upload/file?fileType=PictureSlot2&resourceId=' + this.answerId + '&resourceType=MessagePictures&managerUserId=' + getUserId();
                    this.uploadUrlForPicture3 = process.env.VUE_APP_MAIN_API + '/api/upload/file?fileType=PictureSlot3&resourceId=' + this.answerId + '&resourceType=MessagePictures&managerUserId=' + getUserId();
                }
            }
        },
    },

};

</script>


<style scoped>

</style>
